import { render, staticRenderFns } from "./MemberNotesComponent.vue?vue&type=template&id=edf201a6&scoped=true&"
import script from "./MemberNotesComponent.vue?vue&type=script&lang=js&"
export * from "./MemberNotesComponent.vue?vue&type=script&lang=js&"
import style0 from "./MemberNotesComponent.vue?vue&type=style&index=0&id=edf201a6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edf201a6",
  null
  
)

export default component.exports